const dropdowns = document.querySelectorAll('.dropdown');

for (const dropdown of dropdowns) {
	const select = dropdown.querySelector('select');
	const output = dropdown.querySelector('output');
	if (select && output) {
		select.addEventListener('change', () => {
			output.textContent = select.options[select.selectedIndex].label;
		});
	}
}
