const supportsWebP = () => {
	const canvas = document.createElement('canvas');

	if (canvas.getContext?.('2d')) {
		return canvas.toDataURL('image/webp').startsWith('data:image/webp');
	}

	return false;
};

if (supportsWebP()) {
	document.documentElement.classList.add('webp');
} else {
	document.documentElement.classList.add('no-webp');
}
