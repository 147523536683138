const deleteButtons: NodeListOf<HTMLButtonElement> =
	document.querySelectorAll('.button-delete');

for (const button of deleteButtons) {
	button.addEventListener('click', (event: MouseEvent) => {
		// eslint-disable-next-line no-alert
		const confirmation = window.confirm('Sind Sie sicher?');
		if (!confirmation) {
			event.preventDefault();
		}
	});
}
