const tabs: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
	'.collapsible-list [role=tab] a',
);

const toggleExpandedState = (
	pane: HTMLElement,
	wrapper: HTMLElement,
	isExpanded = false,
) => {
	if (isExpanded) {
		delete wrapper.dataset.open;
		pane.setAttribute('aria-expanded', 'false');
	} else {
		wrapper.dataset.open = 'true';
		pane.setAttribute('aria-expanded', 'true');
	}
};

const setUrl = (hash = '') => {
	if ('pushState' in history) {
		history.pushState('', document.title, window.location.pathname + hash);
	} else {
		location.hash = hash;
	}
};

for (const tab of tabs) {
	const wrapper = tab.parentElement;
	tab.addEventListener('click', (event: MouseEvent) => {
		event.preventDefault();

		const {hash} = new URL(tab.href);
		const id = hash;
		const pane: HTMLElement | null = document.querySelector(id);

		if (pane && wrapper) {
			const isExpanded = pane.getAttribute('aria-expanded') === 'true';
			toggleExpandedState(pane, wrapper, isExpanded);
			setUrl(isExpanded ? '' : id);
		}
	});
}
