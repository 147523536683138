const titlePrefix = 'Fehler im Formular: ';
const errorClass = 'error';

const inputs = {
	comment: '#kommentar',
	name: '#name',
	email: '#email',
	check: '#check',
};

const removeMessage = (input: HTMLInputElement) => {
	if (input.nextElementSibling?.classList.contains(errorClass)) {
		input.nextElementSibling.remove();
	}
};

const setError = (inputId: string, text: string) => {
	const input: HTMLInputElement | null = document.querySelector(inputId);
	if (input) {
		input.setAttribute('aria-invalid', 'true');
		removeMessage(input);
		input.insertAdjacentHTML(
			'afterend',
			`<label for="${inputId.slice(
				1,
			)}" class="${errorClass}">${text}</label>`,
		);
	}
};

const unsetError = (inputId: string) => {
	const input: HTMLInputElement | null = document.querySelector(inputId);
	if (input) {
		input.setAttribute('aria-invalid', '');
		removeMessage(input);
	}
};

// Validate contact form
const contactForm: HTMLFormElement | null = document.querySelector(
	'form[action$="kontakt/senden"]',
);
if (contactForm) {
	contactForm.setAttribute('novalidate', 'true');
	contactForm.addEventListener('submit', (event: Event) => {
		let errors = 0;
		let returnField = '';

		const isEmpty = (inputId: string) => {
			const input: HTMLInputElement | null =
				document.querySelector(inputId);
			if (input) {
				return input.value === '';
			}

			return true;
		};

		const isNoEmailAddress = (inputId: string) => {
			const input: HTMLInputElement | null =
				document.querySelector(inputId);
			if (input) {
				return !input.value.includes('@');
			}

			return true;
		};

		if (isEmpty(inputs.comment)) {
			setError(inputs.comment, 'Bitte hinterlassen Sie eine Nachricht.');
			returnField = returnField || inputs.comment;
			errors++;
		} else {
			unsetError(inputs.comment);
		}

		if (isEmpty(inputs.name)) {
			setError(inputs.name, 'Bitte nennen Sie uns Ihren Namen.');
			returnField = returnField || inputs.name;
			errors++;
		} else {
			unsetError(inputs.name);
		}

		if (isEmpty(inputs.email)) {
			setError(
				inputs.email,
				'Bitte nennen Sie uns Ihre E-Mail-Adresse, damit wir Ihnen antworten kÃ¶nnen.',
			);
			returnField = returnField || inputs.email;
			errors++;
		} else if (isNoEmailAddress(inputs.email)) {
			setError(
				inputs.email,
				'Bitte geben Sie eine korrekte E-Mail-Adresse an.',
			);
			returnField = returnField || inputs.email;
			errors++;
		} else {
			unsetError(inputs.email);
		}

		if (isEmpty(inputs.check)) {
			setError(inputs.check, 'Bitte geben Sie die Summe an.');
			returnField = returnField || inputs.check;
			errors++;
		} else {
			unsetError(inputs.check);
		}

		if (errors > 0) {
			document.title = document.title.replace(titlePrefix, '');
			document.title = titlePrefix + document.title;
			const returnElement: HTMLElement | null =
				document.querySelector(returnField);
			if (returnElement) {
				returnElement.focus();
			}

			event.preventDefault();
		} else {
			document.title = document.title.replace(titlePrefix, '');
		}
	});
}

// Prevent zoom on form elements on iOS
if (/iP(a|o)d|iPhone/.test(navigator.userAgent)) {
	const viewportElement: HTMLMetaElement | null = document.querySelector(
		'meta[name=viewport]',
	);
	if (viewportElement) {
		const vpOriginal = viewportElement.getAttribute('content');
		const vpNoZoom = 'maximum-scale=1,user-scalable=0';
		const elements = document.querySelectorAll('input, textarea, select');
		for (const element of elements) {
			element.addEventListener('focusin', () => {
				viewportElement.setAttribute(
					'content',
					[vpOriginal, vpNoZoom].join(','),
				);
			});
			element.addEventListener('focusout', () => {
				viewportElement.setAttribute('content', String(vpOriginal));
			});
		}
	}
}
