const uploadField: HTMLInputElement | null = document.querySelector('#upload');
const dateField: HTMLInputElement | null = document.querySelector('#date');
const summaryField: HTMLInputElement | null =
	document.querySelector('#summary');

if (uploadField && dateField && summaryField) {
	uploadField.addEventListener('change', () => {
		const {value} = uploadField;
		if (value) {
			const filenameParts = 3;
			const filename = value
				.slice(value.lastIndexOf('\\') + 1)
				.replace(/\.jpe?g|\.pdf$/, '');
			const values = /(\d{4}(?:-\d{2}){2})\s(.*)/.exec(filename);
			if (values && values.length === filenameParts) {
				const event = new Event('input', {
					bubbles: true,
					cancelable: false,
				});
				dateField.value = values[1];
				dateField.dispatchEvent(event);
				summaryField.value = values[2];
				summaryField.dispatchEvent(event);
			}
		}
	});

	summaryField.addEventListener('input', () => {
		const {value} = summaryField;
		const maxlength = 100;
		/* eslint-disable unicorn/prefer-string-replace-all */
		const slug = value
			.trim()
			.toLowerCase()
			.replace(/\u0308/g, 'e')
			.replace(/\u00E4/g, 'ae')
			.replace(/\u00F6/g, 'oe')
			.replace(/\u00FC/g, 'ue')
			.replace(/\u00DF/g, 'ss')
			.replace(/Ã©/g, 'e')
			.replace(/Ã¨/g, 'e')
			.replace(/Ã¡/g, 'a')
			.replace(/Ã /g, 'a')
			.replace(/Ã±/g, 'n')
			.replace(/â¬/g, 'euro')
			.replace(/\W|_/g, '-')
			.replace(/-+/g, '-')
			.slice(0, maxlength - 1);
		/* eslint-enable unicorn/prefer-string-replace-all */

		const slugOutput: HTMLInputElement | null =
			document.querySelector('#slug');
		if (value !== '' && slugOutput) {
			slugOutput.value = slug;
		}
	});
}
