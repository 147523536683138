// eslint-disable-next-line import/no-unassigned-import
import 'fslightbox';

const downloadLinks: NodeListOf<HTMLAnchorElement> = document.querySelectorAll(
	'a[href$=".pdf"], a[download]',
);
const canDownload =
	// @ts-expect-error `externalHost` property is proprietary
	!window.externalHost && 'download' in document.createElement('a');

for (const link of downloadLinks) {
	link.addEventListener('click', (event: MouseEvent) => {
		if (canDownload && link.download !== null) {
			return;
		}

		event.preventDefault();

		const popup = window.open(link.href, 'external', '');
		popup?.focus();
	});
}
