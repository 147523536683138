const skiplink: HTMLElement | null = document.querySelector('.skiplink');
const content = document.querySelector('main');
const activeClass = 'show-sidebar';

const toggleNavigation = (event: MouseEvent) => {
	event.preventDefault();
	document.documentElement.classList.toggle(activeClass);
};

if (skiplink) {
	skiplink.addEventListener('click', toggleNavigation);
}

if (content) {
	content.addEventListener('click', (event: MouseEvent) => {
		if (document.documentElement.classList.contains(activeClass)) {
			toggleNavigation(event);
		}
	});
}
