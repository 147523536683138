type MapApiCallbackFn = () => void;

const map: HTMLElement | null = document.querySelector('#map');
const apiKey = 'AIzaSyDDgVvxSqVZVAAyGiB9rPSl3-s4DbLJuKQ';
const aspectRatio = 0.625;

const loadApi = (callback: MapApiCallbackFn) => {
	const callbackFn = 'googleMapsInit';
	const src = `https://maps.googleapis.com/maps/api/js?callback=${callbackFn}&key=${apiKey}`;

	// Register global init callback
	(window as any)[callbackFn] = callback;

	// Append script
	const script = document.createElement('script');
	script.async = true;
	script.src = src;
	document.body.append(script);
};

const setDimensions = () => {
	if (map) {
		const height = Math.round(map.offsetWidth * aspectRatio);
		map.style.width = '100%';
		map.style.height = `${height}px`;
	}
};

const initializeMap = () => {
	if (map) {
		map.classList.remove('not-initialized');
		loadApi(() => {
			if (!google) {
				return;
			}

			// Initialize the map
			const googleMap = new google.maps.Map(map, {
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				zoom: 11,
				streetViewControl: false,
				scrollwheel: false,
				mapTypeControlOptions: {
					style: google.maps.MapTypeControlStyle.DEFAULT,
				},
			});

			// Parse hCard and position appropriate marker on the map
			const geocoder = new google.maps.Geocoder();
			const geocoderMarker = (position: google.maps.LatLng) => {
				googleMap.setCenter(position);
				const marker = new google.maps.Marker({
					position,
					clickable: false,
				});
				marker.setMap(googleMap);
			};

			const hCards = document.querySelectorAll('.h-card');
			for (const card of hCards) {
				const address =
					card.querySelector('.p-street-address')?.textContent ??
					null;
				const postalCode =
					card.querySelector('.p-postal-code')?.textContent ?? null;
				if (address && postalCode) {
					void geocoder
						.geocode({
							address: `${address}, ${postalCode}`,
						})
						.then(({results}) => {
							geocoderMarker(results[0].geometry.location);
						})
						.catch((_) => false);
				}
			}

			// Resize map on window resize
			window.addEventListener('resize', () => {
				// Apply new height based on existing aspect ratio
				setDimensions();

				// Re-center map
				const center =
					googleMap.getCenter() ?? new google.maps.LatLng(0, 0);
				google.maps.event.trigger(googleMap, 'resize');
				googleMap.setCenter(center);
			});
		});
	}
};

if (map) {
	// Apply dimensions
	setDimensions();

	map.classList.add('not-initialized');
	map.innerHTML = `
		<div class="map-toggle">
			<button type="button">Karte anzeigen</button>
		</div>
		<div class="map-disclaimer">
			<p>Wir verwenden die Google Maps API, um geografische Informationen visuell darzustellen. Mit Klick auf den Button stimmen Sie zu, dass von Google Daten Ã¼ber die Nutzung der Kartenfunktionen erhoben, verarbeitet und genutzt werden.</p>
			<p>NÃ¤here Informationen Ã¼ber die Daten&shy;verarbeitung durch Google kÃ¶nnen Sie den <a href="https://www.google.com/intl/de_de/help/terms_maps.html">Google Maps Nutzerbedingungen</a> und der <a href="https://policies.google.com/privacy">Google DatenschutzerklÃ¤rung</a> entnehmen; dort kÃ¶nnen Sie im Datenschutzcenter auch Ihre persÃ¶nlichen Datenschutz-Einstellungen verÃ¤ndern.</p>
		</div>
	`;

	const toggle = document.querySelector('.map-toggle button');
	if (toggle) {
		toggle.addEventListener('click', initializeMap);
	}
}
